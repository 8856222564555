import Image from 'next/image';
import Link from 'next/link';
import Cookies from 'js-cookie';
import MinProduct from '../Product/MinProduct';
import { OurPicksItemProps } from './constants';
import { useState } from 'react';


const OurPick = (props: OurPicksItemProps) => {
  const { tittle, see_all_link, banner_list, product_list, attrId, customName, expandColor, showBorder } = props;
  const viewport = Cookies.get('viewport');
  const [isHover, setIsHover] = useState(false);

  const render_expand_product = () => {
    let count = 0;

    return product_list.map((product) => {
      return product.product_variant_list.map((variant) => {
        count++;

        return (((viewport === "desktop" && count <= 5) || (viewport === "mobile" && count <= 4)) &&
          <MinProduct
            key={count}
            attribute_value_list={product.attribute_value_list}
            brand={product.brand}
            cash_back={product.cash_back}
            id={product.id}
            main_image={product.main_image}
            image={product.image}
            name={product.name}
            product_discount_percent={product.product_discount_percent}
            product_label_bottom_left_category_page={product.product_label_bottom_left_category_page}
            product_label_left_category_page={product.product_label_left_category_page}
            product_label_right_category_page={product.product_label_right_category_page}
            msrp={product.msrp}
            qty={product.qty}
            sales_price={product.sales_price}
            sku={product.sku}
            slug={product.slug}
            special_price={product.special_price}
            type={product.type}
            url={product.url}
            product_variant_list={product.product_variant_list}
            attrId={variant.attribute_value_list[0]}
            customName={customName}
          />
        )
      })
    })
  }

  const handleHover =() => {
    setIsHover(!isHover);
  }

  return (
    <>
      {(tittle.length > 0 && see_all_link != null && see_all_link.length > 0) ? (
        <div className="flex items-center justify-between mt-8 md:mt-12">
          <h2 className="text-[20px] md:text-[32px] text-primary font-dmsans font-medium">{tittle}</h2>
          <Link href={see_all_link}>
            <div
              className="flex text-[10px] font-montserrat font-normal text-primary px-4 py-2 border border-border md:border-primary hover:border-secondary hover:bg-secondary hover:text-white items-center"
              onMouseEnter={handleHover}
              onMouseLeave={handleHover}
            >
              See more
              {isHover ? (
                <Image
                  src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icon/icon-arrow-white.png"}
                  alt="arrow icon"
                  width={12}
                  height={12}
                  quality={100}
                  className="ml-2"
                />
              ) : (
                <Image
                  src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icon/icon-arrow.png"}
                  alt="arrow icon"
                  width={12}
                  height={12}
                  quality={100}
                  className="ml-2"
                />
              )}
            </div>
          </Link>
        </div>
      ) : (tittle.length > 0 && (see_all_link == null || see_all_link.length == 0)) && (
        <div className="flex justify-center mt-5">
          <h2 className="text-[20px] md:text-[32px] text-primary font-dmsans font-medium -tracking-[1.6px]">{tittle}</h2>
        </div>
      )}
      {banner_list.map((banner, index) => (
        <div key={index} className="flex justify-center">
          {banner.link != null && banner.link.length > 0? (
            <Link href={banner.link}>
              <Image
                src={banner.image_desktop}
                alt=''
                quality={100}
                width={0}
                height={0}
                sizes="100vw"
                className="mb-5 rounded-[10px]"
              />
            </Link>
          ) : (
            <Image
              src={banner.image_desktop}
              alt=''
              quality={100}
              width={0}
              height={0}
              sizes="100vw"
              className="mb-5 rounded-[10px]"
            />
          )}
        </div>
      ))}
      <div className={`${(tittle.length > 0 || (see_all_link !== null && see_all_link.length > 0)) ? "mt-4" : "mt-20"} ${(!showBorder) ? "mb-20" : "pt-12 md:pb-12 border-t md:border-b border-border"}`}>
        {!expandColor && product_list.length > 0 && (
          <div className="grid grid-cols-2 gap-x-4 gap-y-8 md:grid-cols-5">
            {product_list.map((product, index) => {
              return (((viewport === "desktop" && index < 5) || (viewport === "mobile" && index < 4)) &&
                <MinProduct
                  key={index}
                  attribute_value_list={product.attribute_value_list}
                  brand={product.brand}
                  cash_back={product.cash_back}
                  id={product.id}
                  main_image={product.main_image}
                  image={product.image}
                  name={product.name}
                  product_discount_percent={product.product_discount_percent}
                  product_label_bottom_left_category_page={product.product_label_bottom_left_category_page}
                  product_label_left_category_page={product.product_label_left_category_page}
                  product_label_right_category_page={product.product_label_right_category_page}
                  msrp={product.msrp}
                  qty={product.qty}
                  sales_price={product.sales_price}
                  sku={product.sku}
                  slug={product.slug}
                  special_price={product.special_price}
                  type={product.type}
                  url={product.url}
                  product_variant_list={product.product_variant_list}
                  attrId={attrId}
                  customName={customName}
                />
              )
            })}
          </div>
        )}
        {expandColor && product_list.length > 0 && (
          <div className="grid grid-cols-2 md:grid-cols-5 gap-x-4 gap-y-8">
            {render_expand_product()}
        </div>
        )}
      </div>
    </>
  )
}

export default OurPick
