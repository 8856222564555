import Image from 'next/image';
import Link from 'next/link';
import { MinProductItemProps } from "./constants"
import { useEffect, useState } from 'react';
import { AttributeValueItem } from '@/lib/constants';
import { useAttributesStore } from '@/stores/useAttributesStore';
import { get_original_price, get_sales_price } from '@/lib/Definitions';


const MinProduct = (props: MinProductItemProps) => {
  const { attribute_value_list, main_image, image, name, msrp, sales_price, special_price, url, brand, slug, product_variant_list, attrId, customName } = props;
  const { attributes, getAttributes } = useAttributesStore();
  const [showImage, setShowImage] = useState(image);
  const [selectedAttr, setSelectedAttr] = useState(attrId);
  const [attributesData, setAttributesData] = useState(getAttributes());

  useEffect(() => {
    if (selectedAttr !== null || attrId !== null) {
      const variants = product_variant_list.filter(variant => variant.attribute_value_list.indexOf(attrId !== null ? attrId : selectedAttr !== null ? selectedAttr : 0) !== -1);

      if (variants.length > 0) {
        setShowImage(variants[0].cart_image);
      } else {
        setShowImage(image);
      }
    } else {
      setShowImage(image);
    }
  }, [selectedAttr, attrId, image, product_variant_list]);

  useEffect(() => {
    setAttributesData(getAttributes());
  }, [getAttributes, attributes]);

  const render_product_color = () => {
    const color_list: Array<AttributeValueItem> = [];

    attributesData.data.map((attributes, index) => {
      if (attributes.slug === "color") {
        const filtered = attributes.attribute_values.filter((attribute) => attribute_value_list.some((value) => attribute.id === value)).sort((a, b) => (a.order === b.order) ? a.id - b.id : a.order - b.order);

        if (filtered.length > 0) {
          filtered.map((color) => {
            color_list.push(color);
          });
        }
      }
    });

    if (color_list.length > 1) {
      return (
        <div className="flex flex-wrap justify-start mt-2">
          {color_list.map((color, index) => (
            <Image
              src={color.image}
              alt={color.name + " image"}
              width={14}
              height={14}
              quality={100}
              key={index}
              className={`rounded-full cursor-pointer my-2 mr-4`}
              onClick={() => setSelectedAttr(color.id)}
            />
          ))}
        </div>
      )
    } else {
      return (<></>)
    }
  };

  const show_sales_price = () => {
    const sales_price = get_sales_price(props, null);

    if (Array.isArray(sales_price.price)) {
      return (
        <p className={`flex font-bold text-saleCategoryText text-base`}>
          {process.env.NEXT_PUBLIC_CURRENCY}{sales_price.price[0].toLocaleString()} ~
        </p>
      )
    } else {
      return (
        <p className={`flex ${sales_price.promotion ? "text-primaryText font-normal" : "text-saleCategoryText font-bold"} text-base`}>
          {process.env.NEXT_PUBLIC_CURRENCY}{sales_price.price.toLocaleString()}
        </p>
      )
    }
  }

  const show_origin_price = () => {
    const origin_price = get_original_price(props, null);

    if (origin_price === null) {
      return (<p>&nbsp;</p>)
    } else {
      return (
        <p className="text-sm font-medium line-through text-originPrice">
          {process.env.NEXT_PUBLIC_CURRENCY}{origin_price.toLocaleString()}
        </p>
      )
    }
  }

  return (
    <div className="">
      <Link href={url !== null && url !== undefined && url.length > 0 ? url : "/product/" + slug}>
        <div className={`relative pt-[100%] block ${props.qty === 0 ? "mini-product-sold-out" : "mini-product"}`}>
          {props.product_label_left_category_page !== null && props.product_label_left_category_page !== undefined && props.product_label_left_category_page !== "" && (
            <Image
              src={props.product_label_left_category_page}
              alt={name + " label"}
              quality={100}
              width={180}
              height={96}
              className="absolute top-3 left-0 z-10 w-[36%]"
            />
          )}
          {props.product_label_bottom_left_category_page !== null && props.product_label_bottom_left_category_page !== undefined && props.product_label_bottom_left_category_page.length > 0 && (
            <Image
              src={props.product_label_bottom_left_category_page}
              alt={name + " label"}
              quality={100}
              width={130}
              height={25}
              className="absolute bottom-0 left-0 z-10"
            />
          )}
          {props.product_label_right_category_page !== null && props.product_label_right_category_page !== undefined && props.product_label_right_category_page !== "" && (
            <Image
              src={props.product_label_right_category_page}
              alt={name + " label"}
              quality={100}
              width={180}
              height={96}
              className="absolute top-3 right-0 z-10 w-[36%]"
            />
          )}
          {props.qty === 0 && (
            <Image
              src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icon/sold-out.png"}
              alt="Sold Out"
              quality={100}
              width={180}
              height={180}
              className="w-[30%] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-20"
            />
          )}
          <Image
            src={showImage}
            alt={name}
            quality={100}
            width={190}
            height={190}
            className={`absolute top-0 left-0 object-contain w-full h-full align-bottom transition duration-500 ${props.qty === 0 ? "brightness-75" : ""}`}
          />
          <Image
            src={main_image}
            alt={name}
            quality={100}
            width={190}
            height={190}
            className={`absolute top-0 left-0 object-contain w-full h-full align-bottom transition duration-500 opacity-0 ${props.qty === 0 ? "brightness-75" : ""}`}
          />
        </div>
        <p className="mt-2 text-sm font-medium underline font-dmsans text-border">{brand}</p>
        <p className="my-2 text-sm font-medium capitalize text-primaryText line-clamp-3 md:line-clamp-2 text-ellipsis font-dmsans md:h-10">
          {customName === null ? name : name + " - " + customName}
        </p>
        <div className="flex items-end justify-start space-x-2 font-dmsans">
          {show_sales_price()}
          {show_origin_price()}
        </div>
      </Link>
      {/* {brand === "MOOIMOM" && render_product_color()} */}
    </div>
  )
}

export default MinProduct
