import Image from 'next/image';
import Link from "next/link";
import RightInfoBanner from './RightInfoBanner';
import LeftInfoBanner from './LeftInfoBanner';
import { InfoBannerItemProps } from './constants';


const InfoBanner = (props: InfoBannerItemProps) => {

  return (
    <>
      <div className="hidden my-20 md:block">
        {props.style === "right_outer_side" && (
          <RightInfoBanner
            desktop_image={props.desktop_image}
            mobile_image={props.mobile_image}
            image_alt={props.image_alt}
            link={props.link}
            style={props.style}
            text={props.text}
            desc={props.desc}
            button_text={props.button_text}
            custom_text={props.custom_text}
            bg_color={props.bg_color}
            text_color={props.text_color}
            button_bg_color={props.button_bg_color}
            button_text_color={props.button_text_color}
            mb_button_bg_color={props.mb_button_bg_color}
            mb_button_text_color={props.mb_button_text_color}
          />
        )}
        {props.style === "left_outer_side" && (
          <LeftInfoBanner
            desktop_image={props.desktop_image}
            mobile_image={props.mobile_image}
            image_alt={props.image_alt}
            link={props.link}
            style={props.style}
            text={props.text}
            desc={props.desc}
            button_text={props.button_text}
            custom_text={props.custom_text}
            bg_color={props.bg_color}
            text_color={props.text_color}
            button_bg_color={props.button_bg_color}
            button_text_color={props.button_text_color}
            mb_button_bg_color={props.mb_button_bg_color}
            mb_button_text_color={props.mb_button_text_color}
          />
        )}
        {(props.style === "carousel_right" || props.style === "carousel_left") && (
          <Link href={props.link}>
            <Image
              src={props.desktop_image}
              alt={props.image_alt}
              quality={100}
              width={900}
              height={815}
              className="w-full"
            />
          </Link>
        )}
      </div>
      <div className="my-12 md:hidden">
        <Link href={props.link}>
          <div className="relative">
            <Image
              src={props.mobile_image}
              alt={props.image_alt}
              quality={100}
              width={600}
              height={800}
            />
            {props.custom_text && (<div className="absolute bottom-8 w-full">
              <div className={`w-full ${props.text_color} text-[28px] font-dmsans mx-auto font-bold text-center leading-normal`} dangerouslySetInnerHTML={{__html: props.text}}></div>
              {props.desc !== null && props.desc !== undefined && props.desc.length > 0 && (
                <div className={`w-full ${props.text_color} text-sm font-dmsans mx-auto font-normal mt-4 text-center`} dangerouslySetInnerHTML={{__html: props.desc}}></div>
              )}
              <div className={`w-fit ${props.mb_button_bg_color} ${props.mb_button_text_color} p-4 mx-auto mt-8 text-base font-normal`}>
                {props.button_text}
              </div>
            </div>)}
          </div>
        </Link>
      </div>
    </>
  )
}

export default InfoBanner
